import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import Moment from 'react-moment';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Prose from '../components/Prose';

const VERSE_TYPES = {
  PRO: 'Promise',
  CMD: 'Command',
};

const Page = ({ pageContext }) => (
  <Layout container="md">
    <SEO title={`${pageContext.year} Memory Verses`} />
    <Box textAlign="center" mb={6}>
      <Typography variant="h1">
        {pageContext.year} Memory Verses ({pageContext.level.label})
      </Typography>
    </Box>

    <Box mb={8}>
      <Divider />
    </Box>

    <Grid container spacing={4}>
      <Grid item xs={12} md={8}>
        <Prose>
          <Box mt={-3}>
            <p>
              We invite you to join our church family as we memorize these
              Scriptural promises and commands.
            </p>
          </Box>

          <p>
            The purpose of this discipline is primarily to help us remember what
            God has said, and where to find it in the Bible. Please take the
            time to read the entire verse and more importantly, to obey it! The
            Holy Spirit will help us.
          </p>

          <Box mb={2}>
            <Alert>
              Please follow us on Instagram (
              <Link href="https://www.instagram.com/rlcfchurch">
                RLCFChurch
              </Link>
              ) and Twitter (
              <Link href="https://twitter.com/RLCF_Verses">@RLCF_Verses</Link>)
              to stay up to date each week.
            </Alert>
          </Box>

          <Box mb={6}>
            <Alert severity="info">
              We recommend the{' '}
              <Link href="https://cfcindia.com/verses-app">
                CFC Verses Versus App
              </Link>{' '}
              which has many useful resources designed to accompany these memory
              verses.
            </Alert>
          </Box>

          {pageContext.memoryVerses.map((memoryVerse) => (
            <Box key={memoryVerse.id} mb={2}>
              <Card variant="outlined">
                <CardContent>
                  <Box display="flex" mb={1}>
                    <Box mr={1}>
                      <Typography variant="h5">
                        <Moment format="MMMM D">{memoryVerse.date}</Moment>
                      </Typography>
                    </Box>
                    <Typography color="textSecondary">
                      {VERSE_TYPES[memoryVerse.verseType]}
                    </Typography>
                  </Box>
                  <div>
                    <Typography>{memoryVerse.text}</Typography>
                  </div>
                  <div>
                    <Typography variant="h6">
                      {memoryVerse.reference}, {memoryVerse.translation}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </Box>
          ))}
        </Prose>
      </Grid>

      <Grid item xs={12} md={4}>
        <Box mb={4}>
          <Typography variant="h4">Levels</Typography>
        </Box>

        <Box>
          <Button
            component={GatsbyLink}
            to={`/memory-verses/plan/${pageContext.year}`}
            color="secondary"
            endIcon={<ChevronRightIcon />}
          >
            Adults &amp; Youth
          </Button>
        </Box>
        <Box>
          <Button
            component={GatsbyLink}
            to={`/memory-verses/plan/${pageContext.year}/primary`}
            color="secondary"
            endIcon={<ChevronRightIcon />}
          >
            Young Children
          </Button>
        </Box>
        <Box>
          <Button
            component={GatsbyLink}
            to={`/memory-verses/plan/${pageContext.year}/preschool`}
            color="secondary"
            endIcon={<ChevronRightIcon />}
          >
            Preschool Children
          </Button>
        </Box>
      </Grid>
    </Grid>
  </Layout>
);

Page.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default Page;
